<template>
  <v-container fluid>
    <v-list-item three-line>
      <v-list-item-content>
        <p class="display-1 text--primary">
          {{ $t('menu.inspections') }}
        </p>
      </v-list-item-content>
    </v-list-item>
    <v-data-table
      :headers="headers"
      :items="inspectionsList"
      :loading="loading"
      :options.sync="options"
      :footer-props="rowsPerPageItemsOptions"
      :server-items-length="totalFromServer"
      v-model="selectedItems"
      :show-select="showCheckBox"
      checkbox-color="primary"
      :no-data-text="$t('general.ui.not_data_found')"
      :loading-text="$t('general.notification.loadData')"
    >
      <template v-slot:top>
        <v-row class="d-flex justify-space-between align-center">
          <v-col lg="4" md="4" sm="12" xl="4">
            <v-text-field
              dense
              filled
              rounded
              color="primary"
              v-model="search"
              :clearable="true"
              @keypress.enter="searchForText"
              @click:clear="searchAll"
              @click:append="searchForText"
              append-icon="mdi-magnify"
              :label="$t('general.titles.filter')"
              single-line
              hide-details
            />
          </v-col>
          <v-col lg="4" md="4" sm="12" xl="4">
            <v-btn
              small
              tile
              :elevation="0"
              color="gray"
              style="border-radius: 1.5em"
              @click="checkBoxShow"
              class="mr-1"
              :disabled="!(inspectionsList && inspectionsList.length > 0)"
            >
              <span v-if="!showCheckBox">
                <v-icon small color="neuter" left
                  >mdi-checkbox-multiple-marked-outline</v-icon
                >
                <span>{{ $t('general.buttons.checksBoxEnable') }}</span>
              </span>
              <span v-else>
                <v-icon small color="neuter" left
                  >mdi-checkbox-multiple-marked</v-icon
                >
                <span>{{ $t('general.buttons.checksBoxDisable') }}</span>
              </span>
            </v-btn>
          </v-col>
          <v-col lg="4" md="4" sm="8" xl="4">
            <v-toolbar flat color="white">
              <v-spacer></v-spacer>
              <button-group-component :buttons="buttonsConfig">
                <template v-slot:menu-content-1="{ closeMenu }">
                  <v-list-item class="d-block py-2" dense
                               v-if="isIgIntegration"
                  >
                    <v-list-item-title class="mb-1">
                      <span class="neuter--text">Sustentavilidade</span>
                    </v-list-item-title>
                    <div class="d-block">
                      <ViewerComponent :document-id="60556" />
                    </div>
                  </v-list-item>
                  <v-list-item class="d-block py-2" dense>
                    <v-list-item-title class="mb-1">
                        <span class="neuter--text">{{
                            $t('records.buttons.export')
                          }}</span>
                    </v-list-item-title>
                    <div class="d-block">
                        <span v-for="(item, index) in itemsExport">
                          <v-btn :key="index" text @click="exportGeneralDialog(item.action, closeMenu)">
                            <v-icon left small color="neuter">{{ item.icon }}</v-icon>
                            {{ item.title }}
                          </v-btn>
                        </span>
                    </div>
                  </v-list-item>
                  <v-list-item
                    v-if="check([{ domain: 'Survey', permisions: ['Write'] }]) || checkAdmin()"
                    dense
                    class="d-block py-2"
                  >
                    <v-list-item-title>
                        <span class="neuter--text">{{
                            $t('records.buttons.import')
                          }}</span>
                    </v-list-item-title>
                    <div class="d-block">
                      <!--        Import CSV-->
                      <DownloadCSVTemplateComponent :profile="profile" v-on:closeMenu="closeMenu" />
                      <!--        Template CSV-->
                      <ImportCSVComponent :profile="profile" v-on:closeMenu="closeMenu" />
                    </div>
                  </v-list-item>
                </template>
              </button-group-component>

            </v-toolbar>
          </v-col>
        </v-row>
        <!--FILTERS TOOLBAR *********************************************************************-->
        <v-toolbar
          class="mb-3"
          outlined
          elevation="0"
          style="border-radius: 0.3em"
          v-if="filtersChips && filtersChips.length > 0"
        >
          <v-row style="display: contents">
            <v-col cols="12">
              <v-chip-group show-arrows>
                <v-chip
                  small
                  close
                  v-for="chip in filtersChips"
                  :key="chip.value"
                  @click:close="cleanFilter(chip.value)"
                  :disabled="!!(search && search !== '')"
                >
                  <v-icon left color="t-adj-primary darken-3" small>mdi-filter-variant</v-icon>
                  {{ chip.name }}
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <!--FILTERS TOOLBAR *********************************************************************-->
      <template v-slot:item.surveyPercentPunctuation="{ item }">
        <h3 v-if="item.surveyPercentPunctuation > 0">
          <span>{{ item.surveyPercentPunctuation | round(1) }}%</span>
        </h3>
        <span v-else>
          <v-chip small disabled>
            {{ $t('inspections.labels.empty_answer') }}
          </v-chip>
        </span>
      </template>
      <template v-slot:item.location_name="{ item }">
        <ShowMoreComponent :item="item" secondary-field="locations" />
      </template>
      <!--<template v-slot:item.location_name="{ item }">
        <v-tooltip bottom v-for="location in item.locations" :key="location.id">
          <template v-slot:activator="{ on }">
            <v-chip v-on="on" class="ma-1" small>{{
              location | truncate(20)
            }}</v-chip>
          </template>
          <span style="font-size: 12px; color: #f5f5f5">
            {{ location }}
          </span>
        </v-tooltip>
      </template>-->
      <template v-slot:item.personInCharge="{ item }">
        <span>{{ item.personInCharge }}</span>
      </template>

      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              color="neuter"
              small
              class="mr-2"
              @click="getDetails(item)"
            >
              mdi-eye-outline
            </v-icon>
          </template>
          <span>
            {{ $t('general.buttons.show') }}
            <span style="font-size: 12px; color: #aaaaaa">
              {{ $t('menu.inspections') }}
            </span>
          </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              v-if="
                check([{ domain: 'Survey', permisions: ['Update'] }]) ||
                checkAdmin()
              "
              color="neuter"
              class="mr-2"
              small
              @click="editItem(item)"
            >
              mdi-square-edit-outline
            </v-icon>
          </template>
          <span>
            {{ $t('general.buttons.edit') }}
            <span style="font-size: 12px; color: #aaaaaa">
              {{ $t('menu.inspections') }}
            </span>
          </span>
        </v-tooltip>
        <ConfirmDialog
          v-if="
                checkAdmin() ||
                check([{ domain: 'Survey', permisions: ['Delete'] }])
              "
          :item="item"
          :is-icon="true"
          :is-tooltip="true"
          :is-btn="false"
          :title="$t('general.titles.alert')"
          :dialog-text="$t('general.notification.deleteItems')"
          :tp-text="$t('general.buttons.delete')"
          :ts-text="$t('menu.inspections')"
          :tooltip="true"
          btn-title=""
          btn-icon="mdi-trash-can-outline"
          action="archived"
          :frBtnProps="{
            small: true,
            color: 'error',
            textColor: 't-bw-error--text',
            elevation: 0,
            text: false
          }"
        />
      </template>

      <template v-slot:item.data="{ item }">
        <span>{{ getDateFormat(item.data) }}</span>
      </template>
    </v-data-table>

    <!--Dialog Template-->
    <v-row justify="center">
      <v-dialog v-model="dialogTemplate" persistent width="600px">
        <v-card>
          <v-card-title class="headline secondary t-bw-secondary--text">{{
            $t('survey.titles.templateTitle')
          }}</v-card-title>
          <v-card-text class="pa-6">
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  :label="$t('survey.fields.template')"
                  item-color="primary"
                  :items="surveyTemplateList"
                  item-text="name"
                  item-value="id"
                  return-object
                  v-model="templateSelect"
                  small-chips
                  persistent-hint
                  small
                  flat
                  outlined
                  dense
                  color="primary"
                  clearable
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="pa-6">
            <v-spacer></v-spacer>
            <v-btn text
                   color="neuter"
                   class="mr-1"
                   @click="dialogTemplateClose">
              {{ $t('general.buttons.cancel') }}
            </v-btn>
            <v-btn
              elevation="0"
              class="t-bw-primary--text"
              color="primary"
              :disabled="!templateSelect"
              @click="addNew"
            >
              {{ $t('general.buttons.continue') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!--Export PDF-->
    <v-row justify="center">
      <v-dialog v-model="showDialogExportPDF" persistent width="600px">
        <v-card>
          <v-card-title class="headline secondary t-bw-secondary--text">{{
            $t('reports.titles.exportConfigPDF')
          }}</v-card-title>
          <v-card-text class="pa-6">
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  color="primary"
                  :label="$t('reports.titles.orientation')"
                  item-color="primary"
                  :items="pageEnum"
                  item-value="type"
                  item-text="text"
                  v-model="orientation"
                  small-chips
                  outlined
                  dense
                  :disbeled="exportLoading"
                  :no-data-text="$t('general.fields.noDataAvailable')"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="d-flex justify-center">
                <v-switch
                  inset
                  v-model="evidencePresent"
                  color="primary"
                  :disbeled="exportLoading"
                >
                  <template v-slot:prepend>
                    <v-label ref="label"
                      >{{ $t('reports.titles.evidences') }}
                    </v-label>
                  </template>
                </v-switch>
              </v-col>
              <v-col cols="6">
                <v-switch
                  inset
                  v-model="commentPresent"
                  color="primary"
                  :disbeled="exportLoading"
                >
                  <template v-slot:prepend>
                    <v-label ref="label"
                      >{{ $t('reports.titles.comments') }}
                    </v-label>
                  </template>
                </v-switch>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="pa-6">
            <v-spacer></v-spacer>
            <v-btn
              small
              text
              color="neuter"
              class="mr-1"
              @click="dialogExportPDFClose"
              :disbeled="exportLoading"
            >
              {{ $t('general.buttons.cancel') }}
            </v-btn>
            <v-btn
              small
              elevation="0"
              class="t-bw-primary--text"
              color="primary"
              :disabled="!orientation"
              :loading="exportLoading"
              @click="exportPDFInspections"
            >
              {{ $t('general.buttons.confirm') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!--Export CSV-->
    <v-row justify="center">
      <v-dialog v-model="showDialogExportCSV" persistent width="600px">
        <v-card>
          <v-card-title class="headline secondary t-bw-secondary--text">
            {{ $t('reports.titles.exportConfigCSV') }}
            <v-spacer />
            <span style="font-size: 13px" v-if="number > 0">
              {{ number }} {{ $t('records.label.titles.recordsFound') }}
            </span>
            <span style="font-size: 13px" v-else-if="number === 0">
              {{ $t('records.label.titles.notRecordsFound') }}
            </span>
          </v-card-title>
          <v-card-text class="pa-6">
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  color="primary"
                  :label="$t('survey.fields.template')"
                  clearable
                  item-color="primary"
                  :items="templatesForExport"
                  v-model="templateCSVExport"
                  small-chips
                  outlined
                  dense
                  @change="filterByProcessCSV"
                  :loading="loadingCSV"
                >
                  <template v-slot:prepend-item>
                    <v-list-item
                      v-if="
                        filters &&
                        filters.template_names &&
                        filters.template_names.length > 1
                      "
                    >
                      <v-list-item-content class="py-0">
                        <div class="row">
                          <div class="col-6 py-0 text-right">
                            <v-btn
                              large
                              text
                              color="primary"
                              :disabled="loadingCSV"
                              @click="seeTemplates('all')"
                            >
                              {{ $t('records.buttons.show_all') }}
                            </v-btn>
                          </div>
                          <v-divider vertical></v-divider>
                          <div class="col-5 py-0 text-left">
                            <v-btn
                              large
                              text
                              color="primary"
                              :disabled="loadingCSV"
                              @click="seeTemplates('selected')"
                            >
                              {{ $t('records.buttons.by_filtered') }}
                            </v-btn>
                          </div>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mb-2"></v-divider>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="pa-6">
            <v-spacer></v-spacer>
            <v-btn small
                   text
                   class="mr-1"
                   color="neuter"
                   @click="dialogExportCSVClose">
              {{ $t('general.buttons.cancel') }}
            </v-btn>
            <v-btn
              small
              elevation="0"
              class="t-bw-primary--text"
              color="primary"
              :disabled="
                templateCSVExport === null ||
                !(number > 0) ||
                loadingCSV
              "
              :loading="exportLoading"
              @click="exportCSV"
            >
              {{ $t('general.buttons.confirm') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
  import i18n from '@/plugins/i18n'
  import { mapActions, mapGetters } from 'vuex'
  import PermisionService from '../../services/PermisionService'
  import moment from 'moment'
  import ConfirmDialog from '../../components/ConfirmDialog'
  import _ from 'lodash'
  import 'jspdf-autotable'
  import LanguajeService from '../../services/LanguajeService'
  import ShowMoreComponent from "../../components/ShowMoreComponent";
  import DownloadCSVTemplateComponent from '@/module/inspections/components/DownloadCSVTemplateComponent'
  import ImportCSVComponent from '@/module/inspections/components/ImportCSVComponent'
  import ViewerComponent from "@/module/inspections/components/ViewerComponent.vue";
  import { checkSameType } from '@/utils'
  import ButtonGroupComponent from "@/components/common/ButtonGroup/ButtonGroupComponent.vue"

  const FILTER_VALUES = [
    'company_id',
    'pageableDTO',
    'structure',
    ' start_record_date_time',
    'end_record_date_time',
    'storage_device_types_ids',
    'score',
    'min_punctuation',
    'max_punctuation',
    'surveyStateType',
    'structure',
    'process_ids',
    'products_ids',
    'seasons_ids',
    'record_ids',
    'template_names',
    'document_authority_ids',
    'document_type_ids',
    'document_description_ids',
    'document_date',
    'expiration_date',
    'storage_devices_ids',
    'lots',
    'sellers_names',
    'contracts_names',
    'volumeB_range',
    'volumeN_range',
    'quebra_range',
    'contracts_dates',
    'sellers_date',
    'withExternalVisibility',
    'answers'
  ]

  const KEYS = [
    'surveyStateType',
    'company_id',
    'pageableDTO',
    'template_names',
    'score',
    'min_punctuation',
    'max_punctuation',
    'person_in_charge_ids',
    'start_record_date_time',
    'sdi_destination_ids',
    'sdi_origin_ids',
    'answers'
  ]

  export default {
    name: 'Survey',
    components: {
      ButtonGroupComponent,
      ViewerComponent,
      ImportCSVComponent,
      DownloadCSVTemplateComponent,
      ShowMoreComponent,
      ConfirmDialog,
    },
    data: () => ({
      search: '',
      profile: {},
      show: false,
      showDialog: false,
      title: 'Información',
      dialogText: 'Añada un texto a este dialogo',
      rowsPerPageItemsOptions: {
        itemsPerPageOptions: [10, 20, 50, 100, 200],
      },
      showDialogExportCSV: false,
      templateCSVExport: null,
      loadingCSV: false,
      // report PDF
      processPDFExport: null,
      logoPositionX: 0,
      doc: '',
      evidenceListUrl: [],
      indexEvidence: 0,
      evidencesCount: 0,
      totalSurveyToExport: 0,
      menu: false,
      // config
      orientation: null,
      commentPresent: true,
      evidencePresent: true,
      exportLoading: false,
      showDialogExportPDF: false,
      itemsExport: [
        { title: 'PDF', icon: 'mdi-download-multiple', action: 'PDF' },
        { title: 'CSV', icon: 'mdi-download-multiple', action: 'CSV' },
      ],
      itemsActions: [
        {
          title: i18n.t('general.buttons.show'),
          icon: 'mdi-eye-outline',
          action: 'SHOW',
        },
        {
          title: i18n.t('general.buttons.edit'),
          icon: 'mdi-square-edit-outline',
          action: 'EDIT',
        },
        {
          title: i18n.t('general.buttons.delete'),
          icon: 'mdi-trash-can-outline',
          action: 'DELETE',
        },
      ],
      options: {},
      templateSelect: null,
      dialogTemplate: false,
      editedIndexSurvey: -1,
      template_name: null,
      selectedItems: [],
      showCheckBox: false,
      number: null,
      templatesForExport: [],
      defaultPageableDTO: {
        page: 0,
        size: 10,
        sortBy: 'data',
        direction: 'asc',
      }
    }),
    watch: {
      options: {
        async handler() {
          this.optionsTable = this.options
          await this.searchText()
        },
        deep: true,
      },
    },
    computed: {
      buttonsConfig() {
        return [
          {
            type: 'button',
            text: this.$t('general.buttons.filter'),
            icon: 'mdi-filter-variant',
            action: this.openFilters,
            props: {
              small: true,
              elevation: 0,
              color: 'gray',
              disabled: this.loadingTemplates,
              class: 'mr-1',
              style: 'border-radius: 1.5em 0 0 1.5em'
            },
            badge: {
              content: this.cantFilters,
              value: this.cantFilters,
              color: 'neuter',
              bordered: true,
              overlap: true,
              left: true,
            }
          },
          {
            type: 'menu',
            text: this.$t('general.buttons.options'),
            icon: 'mdi-cog',
            props: {
              small: true,
              elevation: 0,
              color: 'gray',
              disabled: !(this.inspectionsList && this.inspectionsList.length > 0),
              class: 'mr-1',
              style: !this.isGranted ? 'border-radius: 0 1.5em 1.5em 0' : ''
            }
          },
          {
            type: 'button',
            text: this.$t('general.buttons.new'),
            icon: 'mdi-plus',
            action: () => (this.dialogTemplate = true),
            props: {
              small: true,
              elevation: 0,
              color: 'gray',
              loading: this.loadingTemplates,
              disabled: !this.check([{ domain: 'Survey', permisions: ['Write'] }]),
              style: 'border-radius: 0 1.5em 1.5em 0',
            },
          },
        ];
      },
      isGranted() {
        return this.check([{ domain: 'Survey', permisions: ['Write'] }]) || this.checkAdmin();
      },
      isIgIntegration() {
        return this.$store.getters['integration/IS_PRESENT']('IG');
      },
      type: {
        get() {
          return this.$store.getters['ui/GET_FILTER_TYPE']
        },
        set(val) {
          this.$store.dispatch('ui/SET_FILTER_TYPE', val)
        },
      },
      drawer: {
        get() {
          return this.$store.getters['ui/GET_DRAWER']
        },
        set(val) {
          this.$store.dispatch('ui/SET_DRAWER', val)
        },
      },
      filters: {
        get() {
          return this.$store.getters['ui/GET_FILTERS']
        },
        set(val) {
          this.$store.dispatch('ui/SET_FILTERS', val)
        },
      },
      optionsTable: {
        get() {
          return this.$store.getters['ui/GET_OPTIONS_TABLE']
        },
        set(val) {
          this.$store.dispatch('ui/SET_OPTIONS_TABLE', val)
        },
      },
      locations: {
        get() {
          return this.$store.getters['ui/GET_LOCATIONS_FILTERS']
        },
        set(val) {
          this.$store.dispatch('ui/SET_LOCATIONS_FILTERS', val)
        },
      },
      filtersObjects: {
        get() {
          return this.$store.getters['ui/GET_OBJECTS_TO_FILTERS']
        },
        set(val) {
          this.$store.dispatch('ui/SET_OBJECTS_TO_FILTERS', val)
        },
      },
      date: {
        get() {
          return this.$store.getters['ui/GET_DATE_FILTERS']
        },
        set(val) {
          this.$store.dispatch('ui/SET_DATE_FILTERS', val)
        },
      },
      filtersChips: {
        get() {
          return this.$store.getters['ui/GET_FILTERS_CHIPS']
        },
        set(val) {
          this.$store.dispatch('ui/SET_FILTERS_CHIPS', val)
        },
      },
      cantFilters: {
        get() {
          return this.$store.getters['ui/GET_COUNT_FILTERS']
        },
        set(val) {
          this.$store.dispatch('ui/SET_COUNT_FILTERS', val)
        },
      },
      surveyToExports: {
        get() {
          return this.$store.getters['inspections/surveyToExports']
        },
        set(val) {
          this.$store.commit('inspections/SET_INSPECTION_TO_EXPORT', val)
        },
      },
      surveyTemplateList: {
        get() {
          return this.$store.getters['survey/surveyTemplateList']
        },
        set(val) {
          this.$store.commit('survey/SET_SURVEY_TEMPLATE_BY_COMPANY_LIST', val)
        },
      },
      allTemplates: {
        get() {
          return this.$store.getters['survey/allTemplates']
        },
        set(val) {
          this.$store.commit('survey/SET_ALL_TEMPLATES_TO_EXPORT', val)
        },
      },
      ...mapGetters({
        loading: 'inspections/loading',
        loadingTemplates: 'survey/loading',
        requireAndMaxRules: 'general/requireAndMaxRules',
        requireRules: 'general/requireRules',
        inspectionsList: 'inspections/inspectionsList',
        allProccess: 'proccess/proccess',
        totalFromServer: 'inspections/recordsByInspectionsTotalCount',
        authorities: 'authority/authorities',
        locationsList: 'authority/authoritiesLocations',
        storages: 'authority/authoritiesStoragesDevices',
        pageEnum: 'reports/pageEnum',
        score: 'inspections/score',
        cantToExport: 'inspections/cantToExport',
        secondaries: 'storage/GET_STORAGES_FOR_FILTERS',
        //----ui-----------------------------------------
        filterHeader: 'ui/GET_HEADERS_INSPECTIONS',
      }),
      config: {
        get() {
          return this.$store.getters['general/config']
        },
        set(val) {
          this.$store.commit('general/SET_CONFIG', val)
        },
      },
      emptySearch() {
        return !!(this.search && this.search !== '')
      },
      headers: () => [
        {
          text: i18n.t('survey.fields.name'),
          value: 'name',
          val: 'template_name',
        },
        {
          text: i18n.t('survey.fields.location'),
          value: 'location_name',
          val: 'locations',
          sortable: false,
        },
        {
          text: i18n.t('survey.fields.date'),
          value: 'data',
          val: 'start_data'
        },
        {
          text: i18n.t('survey.fields.person'),
          value: 'personInCharge',
          val: 'person_in_charge_ids',
        },
        {
          text: i18n.t('survey.fields.score'),
          value: 'surveyPercentPunctuation',
          val: 'punctuation',
          sortable: false,
        },
        {
          text: i18n.t('general.fields.action'),
          value: 'action',
          width: 120,
          sortable: false,
          align: 'center',
        },
      ],
      // Computed filter storage by location
      getStorageByLocationsFiltered() {
        return this.getStorageByLocationsFilteredAction(this.locations)
      },
      selectedQuestions: {
        get() {
          return this.$store.getters['ui/GET_SELECTED_QUESTIONS']
        },
        set(val) {
          this.$store.commit('ui/SELECTED_QUESTIONS', val)
        }
      },
      selectedAnswers: {
        get() {
          return this.$store.getters['ui/GET_SELECTED_ANSWERS']
        },
        set(val) {
          this.$store.commit('ui/SELECTED_ANSWERS', val)
        }
      }
    },

    created() {
      this.type = 'inspections'
      this.$store.commit('ui/SET_FILTERS_SORT', []);

      this.initialize()
    },
    async mounted() {
      this.$store.commit('ui/FILTERS_ORIGINAL_STATE')
      let filtersInspections = JSON.parse(
        localStorage.getItem('filtersInspections')
      )

      if (filtersInspections) {
        this.locations.origin_locations_filters =
          filtersInspections.origin_locations_filters
            ? filtersInspections.origin_locations_filters
            : []
        this.locations.destination_locations_filters =
          filtersInspections.destination_locations_filters
            ? filtersInspections.destination_locations_filters
            : []
        this.filters.sdi_destination_ids =
          filtersInspections.filtersObjects.sdi_destination_ids &&
          filtersInspections.filtersObjects.sdi_destination_ids.length > 0
            ? filtersInspections.filtersObjects.sdi_destination_ids
            : []
        this.filters.sdi_origin_ids =
          filtersInspections.filtersObjects.sdi_origin_ids &&
          filtersInspections.filtersObjects.sdi_origin_ids.length > 0
            ? filtersInspections.filtersObjects.sdi_origin_ids
            : []

        this.filtersObjects = filtersInspections.filtersObjects
          ? filtersInspections.filtersObjects
          : []
        this.date.filtersInspectionsDate =
          filtersInspections.filtersInspectionsDate
            ? filtersInspections.filtersInspectionsDate
            : []
        this.date['record_date_time'] = filtersInspections.record_date_time
          ? filtersInspections.record_date_time
          : ''
        this.date.filtersDate = filtersInspections.filtersDate
          ? filtersInspections.filtersDate
          : []
        this.filters.template_names = filtersInspections.template_names
          ? filtersInspections.template_names
          : this.filters.template_names

        if (this.filters.template_names && this.filters.template_names.length > 0) {
          let answers = []

          if (filtersInspections.answers) {
            await this.fetchQuestions([this.filters.template_names, this.$toast]).finally(() => {
              this.selectedQuestions = filtersInspections.questions.selected
            })
            await this.fetchAnswers([LanguajeService.getLenguajeName(), filtersInspections.questions.selected, this.$toast]).finally(() => {
              this.selectedAnswers = filtersInspections.answers.selected
            })
            _.forEach(filtersInspections.answers.selected, (a) => {
              answers.push(...a)
            })
          }

          this.filters.answers =
            answers &&
            answers.length > 0
              ? answers
              : []
        }

        this.filters.min_punctuation = filtersInspections.filtersObjects.range
          ? filtersInspections.filtersObjects.range[0]
          : this.filtersObjects.range[0]
        this.filters.max_punctuation = filtersInspections.filtersObjects.range
          ? filtersInspections.filtersObjects.range[1]
          : this.filtersObjects.range[1]
        this.filters.score =
          filtersInspections.filtersObjects.range &&
          filtersInspections.filtersObjects.range.length > 0
            ? filtersInspections.filtersObjects.range
            : this.filtersObjects.range
      }

      if (this.date.filtersInspectionsDate) this.dateRangeText()

      if (
        this.locations.origin_locations_filters ||
        this.locations.destination_locations_filters ||
        this.filtersObjects
      ) {
        this.closeFilterDialog()
      }

      this.$root.$on('setFiltersInspections', () => {
        this.closeFilterDialog()
      })

      this.$root.$on('getResultsPaginatedByCompany', () => {
        this.getInspectionsPaginatedByCompany()
      })

      this.$root.$on('archived', (item) => {
        this.archived(item)
      })
    },
    methods: {
      ...mapActions({
        fetchInspectionById: 'inspections/fetchInspectionById',
        fetchListSurvey: 'inspections/fetchListSurvey',
        fetchListInspectionsBySearch:
          'inspections/fetchListInspectionsBySearch',
        fetchInspectionsToExport: 'inspections/fetchInspectionsToExport',
        fetchCantToExport: 'inspections/fetchCantToExport',
        exportInspections: 'inspections/exportInspections',
        fetchListSurveyTemplate: 'survey/fetchListSurveyTemplate',
        fetchAllTemplates: 'survey/fetchAllTemplates',
        change_state: 'survey/change_state',
        fetchQuestions: 'ui/SET_QUESTIONS_BY_TYPE',
        fetchAnswers: 'ui/SET_ANSWERS_BY_QUESTIONS',
        fetchTemplatesForFilters: 'survey/fetchTemplatesForFilters',
      }),
      checkBoxShow() {
        this.showCheckBox = !this.showCheckBox

        if (!this.showCheckBox) this.selectedItems = []
      },
      async searchAll() {
        this.options.page = 1
        await this.getInspectionsPaginatedByCompany()
      },
      async searchForText() {
        this.options.page = 1
        this.$store.commit('ui/FILTERS_ORIGINAL_STATE')
        this.$store.commit('ui/CLEAR_LOCAL_STORAGE')
        await this.searchText()
      },
      async searchText() {
        const keys = ['pageableDTO']
        let filters = _.pick(this.filters, keys)

        filters.companyId = this.profile.company_id
        filters.stringFilter = this.search

        const direction =
          this.options.sortDesc.length === 0 || this.options.sortDesc[0]
            ? 'asc'
            : 'desc'
        const sort =
          this.options.sortBy.length === 0
            ? 'data'
            : _.filter(this.headers, { value: this.options.sortBy[0] })[0].value
        filters.pageableDTO = {
          page: this.options.page - 1,
          size: this.options.itemsPerPage,
          sortBy: sort,
          direction: direction,
        }

        if (this.search && this.search !== '') {
          await this.fetchListInspectionsBySearch([filters, this.$toast])
        } else {
          await this.getInspectionsPaginatedByCompany()
        }
      },
      async openFilters() {
        this.type = 'inspections'

        const filtersInspections = JSON.parse(localStorage.getItem('filtersInspections'))

        setTimeout(() => {
          console.log('init >>> set:location:origin', true)
          this.$root.$emit("set:location:origin", {
            locations: filtersInspections.origin_locations_filters,
            storageDevice: filtersInspections.filtersObjects.sdi_origin_ids
          })
        }, 1000)

        this.drawer = true
      },

      // EXPORT PDF
      async exportPDFInspections() {
        this.exportLoading = true

        const keys = [
          'surveyStateType',
          'company_id',
          'pageableDTO',
          'template_names',
          'min_punctuation',
          'max_punctuation',
          'person_in_charge_ids',
          'start_record_date_time',
          'sdi_destination_ids',
          'sdi_origin_ids',
          'answers'
        ]

        if (!this.filters.pageableDTO)
          this.filters.pageableDTO = this.defaultPageableDTO

        let filters = _.pick(this.filters, keys)

        filters.inspections_ids = []

        if (this.selectedItems && this.selectedItems.length > 0) {
          filters.inspections_ids =
            this.selectedItems && this.selectedItems.length > 0
              ? this.getByProperty(this.selectedItems, 'id')
              : []
        }

        let fields = []
        _.forEach(this.filters, (filter, key) => {
          if (
            (this.filters[key] && !(this.filters[key] instanceof Array) )||
            (this.filters[key] &&
              this.filters[key] instanceof Array &&
              this.filters[key].length > 0) ||
            (this.filters[key] instanceof Date && this.filters[key].length > 0)
          ) {
            switch (key) {
              case 'company_id':
                fields.push({
                  key: i18n.t('modules.name.Company'),
                  value: this.profile.company_name_key,
                })
                return
              case 'template_names':
                fields.push({
                  key: i18n.t('survey.fields.template'),
                  value: this.filters.template_names.join(', '),
                })
                return
              case 'person_in_charge_ids':
                fields.push({
                  key: i18n.t('records.fields.person'),
                  value: this.getByProperty(
                    this.filtersObjects.person_in_charge_ids,
                    'name'
                  ).join(', '),
                })
                return
              case 'sdi_origin_ids':
                let originIds = this.filtersObjects.sdi_origin_ids;
                if (checkSameType(this.filtersObjects.sdi_origin_ids, 'number')) {
                  originIds = this.secondaries.filter((l) => { return this.filtersObjects.sdi_origin_ids.includes(l.storageDeviceId) });
                }
                fields.push({
                  key: i18n.t('records.fields.origin'),
                  value: this.getByProperty(
                    originIds,
                    'control_label'
                  ).join(', '),
                })
                return
              case 'sdi_destination_ids':
                let destinationIds = this.filtersObjects.sdi_destination_ids;
                if (checkSameType(this.filtersObjects.sdi_destination_ids, 'number')) {
                  destinationIds = this.secondaries.filter((l) => { return this.filtersObjects.sdi_destination_ids.includes(l.storageDeviceId) });
                }
                fields.push({
                  key: i18n.t('records.fields.destination'),
                  value: this.getByProperty(
                    destinationIds,
                    'control_label'
                  ).join(', '),
                })
                return
              case 'start_record_date_time':
                if (this.date.filtersDate && this.date.filtersDate.length > 0) {
                  fields.push({
                    key: i18n.t('records.fields.date'),
                    value: this.date.filtersDate,
                  })
                }
                return
              case 'answers':
                if (this.filters.answers && this.filters.answers.length > 0) {
                  fields.push({
                    key: i18n.t('inspections.labels.answers'),
                    value: this.getByProperty(
                      this.filters.answers,
                      'answer'
                    ).join(', '),
                  })
                }
                return
            }
          }
        })

        if (this.filtersObjects.range[0] > this.score[0]) {
          fields.push({
            key: i18n.t('survey.fields.min_punctuation'),
            value: this.filtersObjects.range[0],
          })
        }

        if (this.filtersObjects.range[1] < this.score[1]) {
          fields.push({
            key: i18n.t('survey.fields.max_punctuation'),
            value: this.filtersObjects.range[1],
          })
        }

        filters.filterValues = fields
        filters.language = LanguajeService.getLenguajeName()
        filters.withEvidences = this.evidencePresent
        filters.withComments = this.commentPresent
        filters.orientation = this.orientation
        filters.currentDate = moment(new Date()).format().slice(0, 19)

        if ((this.search && this.search !== '') && !(this.selectedItems && this.selectedItems.length > 0)) {
          filters.export_by_string = true
          filters.surveyStateType = 'PUBLISHED'
          filters.company_id = this.profile.company_id
          filters.string_filter = {
            companyId: this.profile.company_id,
            stringFilter: this.search,
            pageableDTO: this.filters.pageableDTO
          }

          console.log(filters)

          filters = _.pick(filters, ['surveyStateType', 'company_id', 'export_by_string', 'string_filter', 'language', 'withEvidences', 'withDocuments', 'orientation', 'currentDate', 'pageableDTO'])
        } else {
          filters.company_id = this.profile.company_id
          filters.surveyStateType = 'PUBLISHED'
          filters.export_by_string = false
          filters.string_filter = null
        }

        let isAsync = false;
        if (this.selectedItems && this.selectedItems.length > 0) {
          isAsync = this.selectedItems.length > this.config.company.export.inspectionExportLimit
        } else {
          isAsync = this.totalFromServer > this.config.company.export.inspectionExportLimit
        }

        console.log(isAsync)

        await this.exportInspections([filters, 'pdf', this.$toast, isAsync]).finally(
          () => {
            this.exportLoading = false
            this.dialogExportPDFClose()
          }
        )
      },

      translate(key, uppercase) {
        return LanguajeService.getKey3(key)
      },

      //Report CSV
      async filterByProcessCSV() {
        if (this.templateCSVExport) {
          this.number = null
          this.loadingCSV = true

          const keys = [
            'surveyStateType',
            'company_id',
            'pageableDTO',
            'template_names',
            'min_punctuation',
            'max_punctuation',
            'person_in_charge_ids',
            'start_record_date_time',
            'sdi_destination_ids',
            'sdi_origin_ids',
            'answers'
          ]

          if (!this.filters.pageableDTO)
            this.filters.pageableDTO = this.defaultPageableDTO

          let filtered = _.pick(this.filters, keys)

          filtered.inspections_ids = []

          if (this.selectedItems && this.selectedItems.length > 0) {
            filtered.inspections_ids =
              this.selectedItems && this.selectedItems.length > 0
                ? this.getByProperty(this.selectedItems, 'id')
                : []
          }
          filtered.language = LanguajeService.getLenguajeName()
          filtered.company_id = this.profile.company_id
          filtered.template_name = _.cloneDeep(this.templateCSVExport.trim())
          filtered.surveyStateType = 'PUBLISHED'

          if ((this.search && this.search !== '') && !(this.selectedItems && this.selectedItems.length > 0)) {
            filtered.export_by_string = true
            filtered.string_filter = {
              companyId: this.profile.company_id,
              stringFilter: this.search,
              pageableDTO: this.filters.pageableDTO,
              name: _.cloneDeep(this.templateCSVExport.trim())
            }

            filtered = _.pick(filtered, ['company_id', 'language', 'export_by_string', 'string_filter', 'pageableDTO'])
          } else {
            filtered.export_by_string = false
            filtered.string_filter = null
          }

          console.log(filtered)

          await this.fetchCantToExport([filtered, this.$toast])
              .finally(() => {
                this.number = parseInt(this.cantToExport)
                this.loadingCSV = false
              })
        } else {
          this.number = null
        }
      },

      async exportCSV() {
        this.exportLoading = true

        const keys = [
          'surveyStateType',
          'company_id',
          'pageableDTO',
          'template_names',
          'min_punctuation',
          'max_punctuation',
          'person_in_charge_ids',
          'start_record_date_time',
          'sdi_destination_ids',
          'sdi_origin_ids',
          'answers'
        ]

        if (!this.filters.pageableDTO)
          this.filters.pageableDTO = this.defaultPageableDTO

        let filters = _.pick(this.filters, keys)

        filters.inspections_ids = []

        if (this.selectedItems && this.selectedItems.length > 0) {
          filters.inspections_ids =
            this.selectedItems && this.selectedItems.length > 0
              ? this.getByProperty(this.selectedItems, 'id')
              : []
        }

        filters.language = LanguajeService.getLenguajeName()
        filters.company_id = this.profile.company_id
        filters.template_names = [_.cloneDeep(this.templateCSVExport.trim())]
        filters.surveyStateType = 'PUBLISHED'

        if ((this.search && this.search !== '') && !(this.selectedItems && this.selectedItems.length > 0)) {
          filters.export_by_string = true
          filters.string_filter = {
            companyId: this.profile.company_id,
            stringFilter: this.search,
            pageableDTO: this.filters.pageableDTO,
            name: _.cloneDeep(this.templateCSVExport.trim())
          }

          filters = _.pick(filters, ['company_id', 'language', 'export_by_string', 'string_filter', 'pageableDTO'])
        } else {
          filters.export_by_string = false
          filters.string_filter = null
        }

        let isAsync = false;
        if (this.selectedItems && this.selectedItems.length > 0) {
          isAsync = this.selectedItems.length > this.config.company.export.inspectionExportLimit
        } else {
          isAsync = this.totalFromServer > this.config.company.export.inspectionExportLimit
        }

        await this.exportInspections([filters, 'csv', this.$toast, isAsync]).finally(
          () => {
            this.exportLoading = false
            this.dialogExportCSVClose()
          }
        )
      },

      async exportDialogCSV() {
        console.log(this.templatesForExport)
        if (
          this.filters.template_names &&
          this.filters.template_names.length === 1
        ) {
          this.templatesForExport =  _.cloneDeep(this.filters.template_names)
          this.templateCSVExport = _.cloneDeep(this.filters.template_names[0])
          await this.filterByProcessCSV()
        } else if (
          this.filters.template_names &&
          this.filters.template_names.length > 1
        ) {
          this.templatesForExport =  _.cloneDeep(this.filters.template_names)
        } else if (this.selectedItems && this.selectedItems.length === 1) {
          const ids = _.map(this.selectedItems, (s) => s.id);
          this.templatesForExport = await this.fetchTemplatesForFilters([ids, this.$toast, false])

          let template = _.filter(this.templatesForExport, (t) => t === this.selectedItems[0].name)[0]

          if (this.templatesForExport.length > 0) {
            this.templateCSVExport = _.cloneDeep(template)
            await this.filterByProcessCSV()
          }
        } else if (this.selectedItems && this.selectedItems.length > 1) {
          const ids = _.map(this.selectedItems, (s) => s.id);
          this.templatesForExport = await this.fetchTemplatesForFilters([ids, this.$toast, false])

          if (this.templatesForExport.length === 1) {
            this.templateCSVExport = this.templatesForExport[0]
            await this.filterByProcessCSV()
          } else {
            this.loadingCSV = true
            this.templatesForExport = await this.fetchTemplatesForFilters([[], this.$toast, false]);
            this.loadingCSV = false
          }
        } else {
          this.loadingCSV = true
          this.templatesForExport = await this.fetchTemplatesForFilters([[], this.$toast, false]);
          this.loadingCSV = false
        }
        this.showDialogExportCSV = true
      },

      async seeTemplates(action) {
        this.loadingCSV = true
        if (action === 'all') {
          await this.getSurveyTemplate()
          this.loadingCSV = false
        } else if ('selected') {
          let templates = []
          _.forEach(this.filters.template_names, name => {
            templates.push(_.filter(this.allTemplates, { name: name })[0])
          })
          this.allTemplates = templates
          this.loadingCSV = false
        }
      },

      dialogExportPDFClose() {
        this.orientation = null
        this.commentPresent = true
        this.evidencePresent = true
        this.showDialogExportPDF = false
        this.exportLoading = false
      },
      beforeGenerateCSV() {
        this.loadingCSV = true
      },
      dialogExportCSVClose() {
        this.templateCSVExport = null
        this.loadingCSV = false
        this.showDialogExportCSV = false
        this.number = null
        this.surveyToExports = []
      },
      exportDialogPDF() {
        if (
          this.filters.template_names &&
          this.filters.template_names.length > 0
        ) {
          this.processPDFExport = this.filters.template_names[0]
        }
        this.showDialogExportPDF = true
      },
      // General Export
      exportGeneralDialog(type, closeMenu) {
        switch (type) {
          case 'PDF':
            this.exportDialogPDF()
            break
          case 'CSV':
            this.exportDialogCSV()
            break
        }

        closeMenu();
      },
      cleanFilter(tag) {
        let filtersInspections = JSON.parse(
          localStorage.getItem('filtersInspections')
        )
        if (this.filters[tag]) {
          if (this.filtersObjects[tag]) {
            if (filtersInspections.filtersObjects[tag] === 'range') {
              filtersInspections.filtersObjects[tag] = [0, 100]
            }

            this.filtersObjects[tag] = []
            filtersInspections.filtersObjects[tag] = []
          }
          if (tag === 'template_names') {
            filtersInspections.template_names = []
            this.filters.template_names = []
            this.$set(filtersInspections, 'answers',{
              all: [],
              selected: []
            })
            this.selectedAnswers = []
            this.$set(filtersInspections, 'questions',{
              all: [],
              selected: []
            })
            this.selectedQuestions = []
            this.filters.questions = []
            this.filters.answers = []
          }
          if (tag === 'answers') {
            this.$set(filtersInspections, 'answers',{
              all: [],
              selected: []
            })
            this.selectedAnswers = []
            filtersInspections.questions.selected = []
            this.selectedQuestions = []
            this.filters.questions = []
            this.filters.answers = []
          }
          if (tag === 'score') {
            filtersInspections.filtersObjects.range = [
              this.score[0],
              this.score[1],
            ]
            this.$set(this.filtersObjects, 'range', [
              this.score[0],
              this.score[1],
            ])
            this.filters.min_punctuation = this.score[0]
            this.filters.min_punctuation = this.score[1]
          }
          if (tag === 'sdi_origin_ids') {
            this.locations.origin_locations_filters = []
            filtersInspections.origin_locations_filters = []

            this.$root.$emit("set:location:origin", {
              locations: [],
              storageDevice: []
            })
          } else if (tag === 'sdi_destination_ids') {
            this.locations.destination_locations_filters = []
            filtersInspections.destination_locations_filters = []

            this.$root.$emit("set:location:destination", {
              locations: [],
              storageDevice: []
            })
          }
          if (
            tag === 'end_record_date_time' ||
            tag === 'start_record_date_time'
          ) {
            this.date.filtersInspectionsDate = []
            this.date.filtersRecordDate = []
            filtersInspections.filtersInspectionsDate = []
            this.date.filtersDate = []
            filtersInspections.filtersDate = []
            this.filters['end_record_date_time'] = []
            this.filters['start_record_date_time'] = null
            this.filtersObjects.start_record_date_time = null
            filtersInspections.start_record_date_time = null
          }
          this.filters[tag] = null
          filtersInspections[tag] = null
        }
        localStorage.setItem(
          'filtersInspections',
          JSON.stringify(filtersInspections)
        )
        this.filterConvert()
        this.filteredChips()
        this.getCantFilters()
      },

      closeFilterDialog() {
        this.search = ''
        this.filterConvert()
        this.filteredChips()
        this.getCantFilters()
        this.show = false
      },

      async initialize() {
        this.profile = JSON.parse(localStorage.getItem('profile'))
        const promises = []

        promises.push(this.$store.dispatch('authority/loadConfigUser', [this.$toast]))
        promises.push(this.fetchTemplatesForFilters([[], this.$toast, true]))
        promises.push(this.getSurveyTemplate())
        promises.push(this.getAllTemplatesToExport())

        await Promise.all(promises)
      },

      surveyAverage(item) {
        return item.surveyPercentPunctuation + ' %'
      },

      dialogTemplateClose() {
        this.templateSelect = null
        this.dialogTemplate = false
      },

      async getSurveyTemplate() {
        const filters = {
          surveyStateType: ['PUBLISHED'],
        }
        await this.fetchListSurveyTemplate([filters, this.$toast])
      },

      async getAllTemplatesToExport() {
        const filter = {
          surveyStateType: ['PUBLISHED', 'EDITING'],
        }
        await this.fetchAllTemplates([filter, this.$toast])
      },

      getDateFormat(dateString) {
        return moment(new Date(dateString)).format(this.date.dateFormat)
      },

      getInspectionsGetters(search) {
        let items = this.inspectionsList
        if (search) {
          items = this.searchOnAll(items)
        }
        return items
      },

      searchOnAll(items) {
        return items.filter(item => {
          return Object.values(item)
            .join(',')
            .toUpperCase()
            .includes(this.search.toUpperCase())
        })
      },

      async getInspectionsPaginatedByCompany() {
        const direction =
          this.options.sortDesc.length === 0 || this.options.sortDesc[0]
            ? 'asc'
            : 'desc'
        const sort =
          this.options.sortBy.length === 0
            ? 'data'
            : _.filter(this.headers, { value: this.options.sortBy[0] })[0].value
        this.filters.pageableDTO = {
          page: this.options.page - 1,
          size: this.options.itemsPerPage,
          sortBy: sort,
          direction: direction,
        }

        let templatesNames = []
        _.forEach(this.filters.template_names, name => {
          templatesNames.push(name.trim())
        })

        this.filters.company_id = this.profile.company_id
        this.filters.templates_names = templatesNames
        this.filters.surveyStateType = 'PUBLISHED'

        const keys = [
          'surveyStateType',
          'company_id',
          'pageableDTO',
          'template_names',
          'score',
          'min_punctuation',
          'max_punctuation',
          'person_in_charge_ids',
          'start_record_date_time',
          'sdi_destination_ids',
          'sdi_origin_ids',
          'answers'
        ]
        const filters = _.pick(this.filters, keys)

        await this.fetchListSurvey([filters, this.$toast])
        await this.fetchTemplatesForFilters([[], this.$toast]);
      },

      editItem(item) {
        this.editedIndexSurvey = item.id
        localStorage.setItem(
          'editedIndexSurvey',
          JSON.stringify(this.editedIndexSurvey)
        )
        this.$router.push({ name: 'inspectionsAnswer' })
      },
      async getDetails(item) {
        this.editedIndexSurvey = item.id
        localStorage.setItem(
          'editedIndexSurvey',
          JSON.stringify(this.editedIndexSurvey)
        )
        this.profile = JSON.parse(localStorage.getItem('profile'))

        await this.fetchInspectionById([this.editedIndexSurvey, this.$toast])
        await this.$router.push({ name: 'inspectionsDetails' })
      },

      check(permisions) {
        return PermisionService.check(permisions)
      },
      // Check permission Admin
      checkAdmin() {
        return PermisionService.checkAdmin()
      },

      // Confirm Dialog Show
      dialogShow(params) {
        this.title = params.title
        this.dialogText = params.dialogText
        this.$refs.dialogConfirm.openDialog()
      },

      addNew() {
        localStorage.setItem('editedIndexSurvey', JSON.stringify(-1))
        localStorage.setItem(
          'templateId',
          JSON.stringify(this.templateSelect.id)
        )
        this.$router.push({ name: 'inspectionsAnswer' })
      },

      async archived(item) {
        const changeStateDTO = {
          surveyStateType: 'ARCHIVED',
          surveyId: item.id,
        }

        await this.change_state([changeStateDTO, this.$toast, 'delete'])
        await this.getInspectionsPaginatedByCompany()
      },
      getFilterModel() {
        return {
          company_id: null,
          template_names: [],
          person_in_charge_ids: [],
          start_data: null,
          end_data: null,
          pageableDTO: null,
          locations: [],
          punctuation: [this.score[0], this.score[1]],
        }
      },
      getFilterObjectsModel() {
        return {
          person_in_charge_ids: null,
          locations: null,
          start_data: null,
        }
      },
      filterConvert() {
        let filtersInspections = JSON.parse(
          localStorage.getItem('filtersInspections')
        )

        if (filtersInspections) {
          this.filters.min_punctuation =
            filtersInspections.filtersObjects.range[0]
          this.filters.max_punctuation =
            filtersInspections.filtersObjects.range[1]
          this.filters.score =
            filtersInspections.filtersObjects.range &&
            filtersInspections.filtersObjects.range.length > 0
              ? filtersInspections.filtersObjects.range
              : this.filtersObjects.range

          this.filters.person_in_charge_ids =
            filtersInspections.filtersObjects.person_in_charge_ids &&
            filtersInspections.filtersObjects.person_in_charge_ids.length > 0
              ? this.getByProperty(
                  filtersInspections.filtersObjects.person_in_charge_ids,
                  'id'
                )
              : []
          this.filters.sdi_destination_ids =
            filtersInspections.filtersObjects.sdi_destination_ids &&
            filtersInspections.filtersObjects.sdi_destination_ids.length > 0
              ? filtersInspections.filtersObjects.sdi_destination_ids
              : []
          this.filters.sdi_origin_ids =
            filtersInspections.filtersObjects.sdi_origin_ids &&
            filtersInspections.filtersObjects.sdi_origin_ids.length > 0
              ? filtersInspections.filtersObjects.sdi_origin_ids
              : []
          this.filters.template_names =
            filtersInspections.template_names &&
            filtersInspections.template_names.length > 0
              ? filtersInspections.template_names
              : []

          let answers = []
          if (filtersInspections.answers)
            _.forEach(filtersInspections.answers.selected, (a) => {
              answers.push(...a)
            })

          this.filters.answers =
            answers &&
            answers.length > 0
              ? answers
              : []

          this.filters.start_record_date_time =
            filtersInspections.filtersInspectionsDate.length > 0
              ? [
                  moment(filtersInspections.filtersInspectionsDate[0]).format(
                    'YYYY-MM-DD'
                  ) + 'T00:00:01',
                  moment(filtersInspections.filtersInspectionsDate[1]).format(
                    'YYYY-MM-DD'
                  ) + 'T23:59:59',
                ]
              : []
        }
        this.getInspectionsPaginatedByCompany()
      },
      getCantFilters() {
        let cant = 0
        const filters = _.pick(this.filters, KEYS)
        _.forEach(filters, (value, key) => {
          if (!FILTER_VALUES.includes(key) && value !== null) {
            cant +=
              key === 'end_record_date_time' ||
              key === ' start_record_date_time'
                ? 1
                : value.length
          }

          cant +=
              key === 'score' &&
                (value[0] > this.score[0] || value[1] < this.score[1]) &&
                JSON.stringify(value) !==
                  JSON.stringify([this.score[0], this.score[1]])
              ? 1
              : 0

          cant +=
            key === 'template_names' && value.length > 0 ? 1 : 0

          cant +=
            key === 'answers' && value.length > 0 ? 1 : 0
        })

        this.cantFilters = cant
      },
      roundArray(arr) {
        return _.map(arr, val => Math.round(val * 100) / 100);
      },
      filteredChips() {
        let chips = []
        const filters = _.pick(this.filters, KEYS)
        _.forEach(filters, (value, key) => {
          if (
            !FILTER_VALUES.includes(key) &&
            this.filters[key] !== null &&
            this.filters[key].length > 0
          ) {
            chips.push({
              value: key,
              name: _.filter(this.filterHeader, { val: key })[0].text,
            })
          }

          if (
            key === 'score' &&
            (value[0] > this.score[0] || value[1] < this.score[1]) &&
            JSON.stringify(value) !==
              JSON.stringify([this.score[0], this.score[1]])
          ) {
            chips.push({
              value: key,
              name: _.filter(this.filterHeader, { val: key })[0].text,
            })
          }

          if (key === 'template_names' && value.length > 0) {
            chips.push({
              value: key,
              name: _.filter(this.filterHeader, { val: key })[0].text,
            })
          }

          if (key === 'answers' && value.length > 0) {
            chips.push({
              value: key,
              name: _.filter(this.filterHeader, { val: key })[0].text,
            })
          }
        })

        this.filtersChips = chips
      },
      getByProperty(array, property) {
        return _.map(array, property)
      },
      getStorageByLocationsFilteredAction(data) {
        return data && data.length > 0
          ? _.filter(this.storages, storage => {
              if (data.includes(storage.primaryLocation.id)) {
                return storage
              }
            })
          : []
      },
      dateRangeText() {
        let [year, month, day] = '-'
        let [year2, month2, day2] = '-'
        if (this.date.filtersInspectionsDate.length > 1) {
          if (this.date.filtersInspectionsDate[0]) {
            ;[year, month, day] = this.date.filtersInspectionsDate[0].split('-')
            const currentTime = moment()
            this.filters.start_record_date_time =
              moment(this.date.filtersInspectionsDate[0]).format('YYYY-MM-DD') +
              'T00:00:01'
          }
          if (this.date.filtersInspectionsDate[1]) {
            ;[year2, month2, day2] =
              this.date.filtersInspectionsDate[1].split('-')
            const currentTime = moment()
            this.filters.end_record_date_time =
              moment(this.date.filtersInspectionsDate[1]).format('YYYY-MM-DD') +
              'T23:59:59'
            this.filters.start_record_date_time = [
              this.filters.start_record_date_time,
              this.filters.end_record_date_time,
            ]
          }
          this.date.filtersDate = `${day}/${month}/${year} ~ ${day2}/${month2}/${year2}`
        }
      },
      filterByDate(field) {
        this.date[field] = false
      },
    },

    beforeDestroy() {
      this.type = null;
      console.log('Inspections view destroyed');
    },

    destroyed() {
      this.$root.$off('setFiltersInspections')
      this.$root.$off('getResultsPaginatedByCompany')
      this.$root.$off('archived')

      this.$store.commit('general/SET_ORIGINAL_STATE_CONFIG')
      this.$store.commit('ui/FILTERS_OBJECTS_ORIGINAL_STATE');
    },
  }
</script>

<style scoped>
  .v-data-table {
    margin: 25px;
  }
  .v-menu__content {
    border-radius: .5em;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
    margin-top: 10px;
  }
  :deep(.v-chip-group .v-slide-group__content) {
    display: flex !important;
    justify-content: flex-start !important;
  }
  :deep(.v-toolbar__content) {
    padding: 0 !important;
  }
</style>
