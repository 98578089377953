<template>
  <v-dialog v-model="dialog" @click:outside="dialog = !dialog" persistent width="793px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn text @click='fetchCertificateFile' :loading="loading">
        <v-icon color='gray' left small>mdi-eye</v-icon>
        {{ $t('general.buttons.view') }} {{ $t('tokens.titles.certificate') }}
      </v-btn>
    </template>
    <v-card v-if="blob" class="fill-height">
      <v-toolbar class="toolbar__fixed px-4">
        <v-btn :loading="loading" @click="downloadCertificate" variant="elevated" color="primary" elevation="0" border="0">
          {{ $t('general.buttons.download') }}
          <v-icon color="white" right size="24" small>mdi-download</v-icon>
        </v-btn>
        <v-spacer/>
        <v-btn color="t-bw-secondary" text icon @click="dialog = !dialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="mx-0 px-0">
        <vue-pdf-embed class="pt-12" :source="blob" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import {mapActions} from "vuex";

export default {
  name: "ViewerComponent",

  components: {
    VuePdfEmbed
  },

  props: {
    documentId: {
      type: Number,
      required: true,
    }
  },

  data() {
    return {
      dialog: false,
      loading: false
    };
  },

  computed: {
    blob: {
      get() {
        return this.$store.getters['inspections/blob']
      },
      set(val) {
        this.$store.commit('inspections/SET_BLOB', val)
      }
    },
  },

  methods: {
    ...mapActions({
      fetchCertificate: "inspections/fetchCertificate",
      downloadCertificate: "inspections/downloadCertificate"
    }),
    async fetchCertificateFile() {
      this.loading = true

      await this.fetchCertificate(this.documentId)
          .then(() => {
            this.dialog = true;
          })
          .catch(() => {
            this.blob = null;
            this.dialog = false;
          })
          .finally(() => {
            this.loading = false
          })
    },
  },
  beforeDestroy() {
    URL.revokeObjectURL(this.blob);
    this.blob = null;
  },
};
</script>

<style scoped>
.toolbar__fixed {
  height: 64px;
  position: fixed;
  z-index: 1;
  width: 793px;
  max-width: 100%;
}
</style>
